<template>
  <b-card class="blog-edit-wrapper">
    <div class="text-center">
      <h4 class="mt-5">
        Add Specific Prices
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
        ref="form">
        <b-row>
          <b-col md="9">
            <b-form-group
              label="Visitors From"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Visitors"
                rules="required">
                <v-select
                  id="blog-edit-"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.country_id"
                  label="name"
                  :options="VisitorList"
                  :reduce="(val) => val.id">
                  <template #no-options="{ search, searching, loading }">
                    No data ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <label class="visitor-to">Visitors To</label>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="City"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="">
                <v-select
                  id="blog-edit-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.city_id"
                  label="name"
                  :options="citiesList"
                  :reduce="(val) => val.id" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Category"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="">
                <v-select
                  id="blog-edit-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.category_id"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category_id)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Course"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider #default="{ errors }" name="Course" rules="">
                <v-select
                  id="blog-edit-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.course_id"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val.id">
                  <template #no-options="{ search, searching, loading }">
                    No data ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Rate"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider #default="{ errors }" name="rate" rules="required">
                <v-select
                  id="blog-edit-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.by_rate"
                  label="name"
                  :options="rateList"
                  :reduce="(val) => val.id" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Discount/Add"
              label-for="blog-edit-slug"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="price"
                rules="required">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.add"
                  label="name"
                  :options="addList"
                  :reduce="(val) => val.id" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Percentage"
              label-for="blog-edit-slug"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Percentage"
                rules="required">
                <b-form-input
                  id="blog-edit-slug"
                  type="number"
                  v-model="addCourseForm.percentage" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4" xl="4">
            <b-form-group
              label="Start Date"
              label-for="blog-edit-course"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules="required">
                <b-form-datepicker
                  id="example-datepicker2"
                  v-model="addCourseForm.start"
                  class="mb-1" />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" xl="4">
            <b-form-group
              label="End Date"
              label-for="blog-edit-course"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules="">
                <b-form-datepicker
                  id="example"
                  v-model="addCourseForm.end"
                  class="mb-1" />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-10">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save">
              Add
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary">
              Reset
            </b-button> -->
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BCardTitle,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";
export default {
  setup(props, { emit }) {
    // const store = useStore();

    const categoriesList = ref([]);
    const coursesList = ref([]);
    const citiesList = ref([]);
    const VisitorList = ref([]);
    const addList = ref([
      {
        name: "Add",
        id: 1,
      },
      {
        name: "Discount",
        id: 0,
      },
    ]);
    const rateList = ref([
      {
        name: "By Rate",
        id: 1,
      },
      {
        name: "By Amount",
        id: 0,
      },
    ]);
    store.dispatch("price/getVistior").then((response) => {
   
      VisitorList.value = response.data.data;
    });
    const getCoursesByCategory = (id) => {
      addCourseForm.course_id = "";
      if( addCourseForm.city_id=='online'){
        store
        .dispatch("categories/getCoursesByCategory", {
          " category_id": id,
          online:1
        })
        .then((response) => {
         //   console.log("online", response.data);
          coursesList.value = response.data.data;
        });

      }else{
      store
        .dispatch("categories/getCoursesByCategory", {
          " category_id": id,
        })
        .then((response) => {
       
          coursesList.value = response.data.data;
        });
      }
    };
   

    store.dispatch("cities/cititesList").then((response) => {
     //   console.log("cic", response.data);
      citiesList.value = response.data;
      let item = {
        name: "Online",
        id: "online",
      };
      citiesList.value = [...citiesList.value, item];
    });

    store.dispatch("categories/AllCategory").then((response) => {
      
      categoriesList.value = response.data.data;
    });
    const addCourseForm = reactive({
      country_id: "",
      course_id: "",
      city_id: "",
      by_rate: "",
      start: "",
      end: "",
      add: "",
      percentage: "",

      category_id: "",
    });

    const addCourseFormvalidate = ref();
    const form = ref();
    const save = () => {
      ;
      const formData = new FormData();

      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          formData.append("country_id", addCourseForm.country_id);
          formData.append("course_id", addCourseForm.course_id);
          formData.append("city_id", addCourseForm.city_id);
          formData.append("by_rate", addCourseForm.by_rate);
          formData.append("add", addCourseForm.add);
          formData.append("category_id", addCourseForm.category_id);
          formData.append("start_date", addCourseForm.start);

          formData.append("end_date", addCourseForm.end);
          formData.append("percentage", addCourseForm.percentage);
          formData.append("applied", 0);
         //   console.log("formData", formData);
          store
            .dispatch("price/addSpecificPrice", formData)
            .then((response) => {
              emit("add-price");
              form.value.reset();

              Vue.swal({
                title: "Price Added ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    };
    return {
      VisitorList,
      addList,
      rateList,
      addCourseForm,

      getCoursesByCategory,
      addCourseFormvalidate,
      categoriesList,
      coursesList,
      citiesList,

      form,
      save,
      required,
      email,
    };
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    BCardTitle,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
label.visitor-to {
  font-size: 16px;
  margin: 12px 0px;
}
</style>
