<template>
  <div class="table-list">
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              v-model="perPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
         
       
        

          <!-- Search -->
      
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
          <b-col cols="6"     class="d-flex align-items-end justify-content-end mb-1 mb-md-0">
            <b-button
    
              variant="primary"
              class="mr-1"
              @click="getData"
            >
              Save Changes
            </b-button>
          </b-col>

        </b-row>
      </div>

      <b-table
        ref="refPriceListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="PricesList"
        striped
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Status -->
        <template #thead-top="data">
          <b-tr>
            <b-th colspan="1" style="background:#fff"><span class="sr-only"></span></b-th>
            <b-th variant="secondary" colspan="3">Visiting </b-th>
            <b-th colspan="6" style="background:#fff"><span class="sr-only"></span></b-th>
          
          </b-tr>
        </template>
        <template #cell(add)="data">
          <span v-if="data.item.add == 0">Discount</span>
          <span v-else>Add</span>
        </template>
        <template #cell(course)="data">
          <span v-if="data.item.course==null">All</span>
          <span v-else>{{data.item.course.name}}</span>
        </template>
        <template #cell(category)="data">
          <span v-if="data.item.category==null">All</span>
          <span v-else>{{data.item.category.type}}</span>
        </template>
        <template #cell(city)="data">
          <span v-if="data.item.city==null">All</span>
          <span v-else>{{data.item.city.name}}</span>
        </template>
         <template #cell(by_rate)="data">
          <span v-if="data.item.by_rate == 0">By Amount</span>
          <span v-else>By Rate</span>
        </template>
        <template #cell(applied)="data">
          <b-form-group class="mb-0">
          
     <b-form-radio-group
       v-model="data.item.applied"

       class="demo-inline-spacing"
     >
       <b-form-radio
         :value=yes
          v-model="data.item.applied"
       
       >
         yes
       </b-form-radio>
       <b-form-radio
         :value=no
          v-model="data.item.applied">
         No
       </b-form-radio>
      
     </b-form-radio-group>
   </b-form-group>
           </template>
     
        <!-- Column: Actions -->
       
        <template #cell(actions)="data">
          
          <a
          >
             
           <router-link
          
           :to="`/update-specific-price/${ data.item.id}`"
           style="margin: 0px 12px">
           <feather-icon icon="EditIcon" />
         </router-link>
          </a>
          <a  v-if="$can('delete', 'specific_price')" role="button" @click="DeletePrice(data.item.id)">
            <feather-icon icon="TrashIcon" />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownItemButton
  ,BFormRadio,BFormRadioGroup,BFormGroup
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, computed, watch, reactive } from "@vue/composition-api";
import Vue from "vue";
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownItemButton,
    vSelect
    ,BFormRadio,BFormRadioGroup,BFormGroup
  },
  setup() {
    const PricesList = ref([]);
    const meta = reactive({});
    const perPageOptions = [10, 25, 50, 100, 1];
    const perPage = ref(10);
    const refPriceListTable = ref(null);
    const totalItems = ref(0);
    const searchQuery = ref("");
    const currentPage = ref(1);
    const yes=ref(true)
    const no=ref(false)


    const refetchData = () => {
      refPriceListTable.value.refresh();
    };
    watch([currentPage, perPage, searchQuery], () => {
    
      GetAllPrices();
    });
    const GetAllPrices = (ctx, callback) => {
      store
        .dispatch("price/getAllSpecificPrice", {
          "filter[search]": searchQuery.value,

          per_page: perPage.value,
          page: currentPage.value,
        })
        .then((response) => {
      //   console.log(response.data.data)
          PricesList.value=response.data.data
          // const { data } = response.data;
          totalItems.value = response.data.meta.total;
        ;

          // callback(data);
        });
    };
    GetAllPrices()
    const dataMeta = computed(() => {
      const localItemsCount = refPriceListTable.value
        ? refPriceListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalItems.value,
      };
    });
    // GetAllPrices()
    const getData=()=>{

     store
            .dispatch("price/updateChange", PricesList.value)
            .then((response) => {
              Vue.swal({
                title: " Changes saved successfuly ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
  }
       
    const DeletePrice = (id) => {
      Vue.swal({
        icon: "warning",
        title: "Are You Sure?",
        confirmButtonText: "YES",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then((result) => {
        if (result.value) {
          store.dispatch("price/DeleteSpecificPrice", id).then((response) => {
            GetAllPrices();
            Vue.swal({
              title: "Specific Price Deleted ",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        }
      });
    };
    const tableColumns = [
      { key: "country.name", label: "Visitor From", sortable: true },
      { key: "course", label: "Course Name" },
      { label: "City", key: "city" },
      {
        key: "category",
        label: "Category"

        
      },
      { key: "percentage", label: "Percentage"  },
      
       { key: "by_rate", label: "ByRate/ByAmount"  },
      { key: "add", label: "Add/Discount"  },
      { key: "applied", label: "Enable/Disable" },
       { key: "end_date", label: "End"  },
      { key: "start_date", label: "Start" },
      { key: "actions" },
    ];

    return {
      tableColumns,
      yes,
        no,
      PricesList,
      DeletePrice,
      meta,
      perPageOptions,
      totalItems,
      currentPage,
      perPage,
      dataMeta,
      refPriceListTable,
      refetchData,
      GetAllPrices,
      searchQuery,
      PricesList,
      getData
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
